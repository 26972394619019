import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import i18n from "./../i18n";
// import store from "./../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // added By Magdy ==================
  // scrollBehavior(to, from, savedPosition) {
  //   // Disable automatic scroll if navigating to the "EducationalScheduleTimes" route
  //   if (to.name === "EducationalScheduleTimes") {
  //     return null; // Return null to disable automatic scrolling
  //   }

  //   // For other routes, use the default scroll behavior
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
  // scrollBehavior(to, from, savedPosition) {
  //   // Check if the route's name is "EducationalScheduleTimes"
  //   if (to.name === "EducationalScheduleTimes") {
  //     // Scroll to a specific element or position within the page
  //     return { selector: "#section-to-scroll-to" }; // Replace with your actual element's ID or position
  //   }

  //   // For other routes, use the default scroll behavior
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
  // End ==================
});
// check if InvalidLoginClode
// router.beforeEach((to, from, next) => {
//   if (store.getters.invalidLoginCodeStatus) {
//     next({ name: "InvalidLoginClode" });
//   } else {
//     next({ name: store.getters.userHomePage });
//   }
// });

// synch route title
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.pageName} | ${i18n.t("projectName")}`;
  next();
});

export default router;

